import { toTheme } from '@theme-ui/typography'
import sternGrove from 'typography-theme-stern-grove'
import { merge } from 'theme-ui'

const theme = merge(toTheme(sternGrove), {
  colors: {
      text: `#162740`,
      primary: `#BF6356`,
      heading: `#646464`,
  },
  fonts: {
    body: ['Open Sans', ...sternGrove.bodyFontFamily].join(', ')
  },
  styles: {
    h1: {
      fontWeight: 900
    }
  }
})

export default theme